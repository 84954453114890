import { motion, useInView } from 'framer-motion';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const Gallery = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
        delay: 0.5,
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  const handleNextClickStay = () => {
    const swiper = document.querySelector('.grid-slider .swiper').swiper;
    swiper.slideNext();
  };
  const handlePrevClickStay = () => {
    const swiper = document.querySelector('.grid-slider .swiper').swiper;
    swiper.slidePrev();
  };

  return (
    <section
      className={`grid-slider lg:py-60 py-30${
        module?.extraClass ? ' ' + module.extraClass : ''
      }${
        module?.backgroundStyle === 'background with pattern'
          ? ' bg-pattern'
          : ''
      }`}
    >
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView || hasPlayed ? 'show' : 'hidden'}
        variants={container}
        className="relative fade-ani"
      >
        <Swiper
          loop={true}
          slidesPerView={2.5}
          allowTouchMove={true}
          centeredSlides={true}
          grabCursor={true}
          speed={1000}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            100: {
              slidesPerView: 1.3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2.5,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 2.5,
              spaceBetween: 0,
            },
          }}
        >
          {module?.images?.length > 0 &&
            module.images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="grid-slider-inner">
                  <GatsbyImage image={getImage(image)} alt={image?.altText} />
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
        <div className="swiper-arrow swiper-arrow-red">
          <a
            className="button-prev cursor-pointer absolute top-[30%] left-[29vw] mdscreen:left-[30px] translate-y-minus_50 lgscreen:top-[28%] mdscreen:top-[21%] z-9"
            onClick={handlePrevClickStay}
          >
            <img
              src="/images/white-arrow.svg"
              loading="lazy"
              className="scale-x-[-1]"
              alt=""
            />
          </a>
          <a
            className="button-next cursor-pointer absolute top-[30%] lgscreen:top-[28%] mdscreen:top-[21%] right-[29vw] mdscreen:right-[30px] translate-y-minus_50 z-9"
            onClick={handleNextClickStay}
          >
            <img src="/images/white-arrow.svg" loading="lazy" alt="" />
          </a>
        </div>
      </motion.div>
    </section>
  );
};

export default Gallery;
export const ExperienceGalleryFragment = graphql`
  fragment ExperienceGalleryFragment on WpExperience_Experiencecontent_Experience_Gallery {
    id
    hideSection
    extraClass
    backgroundStyle
    images {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
    }
  }
`;

export const GalleryFragment = graphql`
  fragment GalleryFragment on WpPage_Pagecontent_PageContent_Gallery {
    id
    hideSection
    extraClass
    images {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
    }
  }
`;
